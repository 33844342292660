<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Tambah Admin",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        role: "role?orderby=id&sort=desc&status=aktif",
        redirect: "admin",
        api: "user",
      },
      title: "Tambah Admin",
      items: [
        {
          text: "Admin",
        },
        {
          text: "Tambah Admin",
          active: true,
        },
      ],
      status: [
        { text: "Aktif", value: "aktif" },
        { text: "Tidak Aktif", value: "tidak aktif" },
      ],

      roles: [],
      submitform: false,
      model: {
        full_name: null,
        password: null,
        role_id: null,
        status: null,
        email: null,
        profile_picture: null,
      },
      routeBase: "admin",
      responses: {
        message: null,
      },
    };
  },
  mounted() {
    let _ = this;
    _.getRole();
    _.getData();
  },

  methods: {
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
          let _ = this;
          _.model = response.data.data;
        });
      }
    },
    save() {
      let _ = this;
      let formData = new FormData();
      if (this.$route.meta.action == "store") {
        formData.append("full_name", _.model.full_name);
        formData.append("email", _.model.email);
        formData.append("password", _.model.password);
        formData.append("role_id", _.model.role_id);
        formData.append("status", _.model.status);
        formData.append("profile_picture", this.$refs.file.$el.files[0]);

        api
          .post(_.config.api, formData)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Create Success",
              },
            });
          })
          .catch((e) => {
            console.log(e);
            this.responses.message = e.response.data.message;
          });
      } else {
        let formData = new FormData();
        if (typeof this.profile_picture !== "string") {
          formData.append("full_name", _.model.full_name);
          formData.append("email", _.model.email);
          formData.append("role_id", _.model.role_id);
          formData.append("status", _.model.status);
          formData.append("profile_picture", this.$refs.file.$el.files[0]);
        } else {
          formData.append("full_name", _.model.full_name);
          formData.append("email", _.model.email);
          formData.append("role_id", _.model.role_id);
          formData.append("status", _.model.status);
        }
        api
          .put(_.config.api + "/" + _.$route.params.id, formData)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Edit Success",
              },
            });
          })
          .catch((e) => {
            console.log(e);
            this.responses.message = e.response.data.message;
          });
      }
    },
    clickref() {
      console.log(this.$refs.file.$el.files[0]);
      this.model.profile_picture = this.$refs.file.$el.files[0];
      let reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      reader.onload = (e) => {
        this.$refs.image_tmb.src = reader.result;
      };
      reader.readAsDataURL(this.model.profile_picture);
      this.$forceUpdate();
    },
    getRole() {
      let _ = this;
      api.get(_.config.role).then((response) => {
        _.roles = response.data.data.rows;
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-if="responses.message"
              variant="danger"
              class="mb-0"
              show
              dismissible
            >
              <p class="mb-0">{{ responses.message }}</p>
            </b-alert>
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                @submit.prevent="handleSubmit(save)"
                class="form-horizontal "
              >
                <div class="row">
                  <div class="col-md-2 text-center">
                    <div class="p-4">
                      <b-img
                        ref="image_tmb"
                        :src="
                          model.profile_picture
                            ? model.profile_picture
                            : 'https://via.placeholder.com/200.png?text=No+Image'
                        "
                        rounded="circle"
                        alt="100x100"
                        width="100"
                        height="100"
                      ></b-img>
                      <b-button
                        variant="primary"
                        class="w-70 text-center mt-2"
                        @click="$refs.file.$el.click()"
                        >Upload Photo</b-button
                      >
                      <b-form-file
                        ref="file"
                        @input="clickref"
                        accept=".jpg, .png, .gif"
                        :hidden="true"
                        plain
                      />
                    </div>
                  </div>
                  <div class="col-md-10 mt-4">
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider
                          name="Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Nama"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.full_name"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div
                        class="col-md-6"
                        v-if="$route.meta.action == 'store'"
                      >
                        <ValidationProvider
                          name="Password"
                          :rules="
                            $route.meta.action == 'store'
                              ? 'required|min:8'
                              : 'min:8'
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Password"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.password"
                              type="password"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Email"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Email"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.email"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Role"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Role"
                            label-for="input-2"
                          >
                            <select
                              class="form-control"
                              v-model="model.role_id"
                            >
                              <option value="">Pilih Role</option>
                              <option
                                v-for="(i, index) in roles"
                                :key="index"
                                :value="i.id"
                              >
                                {{ i.name }}
                              </option>
                            </select>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Status"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Status"
                            label-for="input-2"
                          >
                            <b-form-radio-group
                              id="radio-group-1"
                              v-model="model.status"
                              :options="status"
                              name="radio-options"
                            ></b-form-radio-group>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div
                        class="col-md-6"
                        v-if="$route.meta.action == 'store'"
                      ></div>

                      <div class="col-md-6">
                        <div class="form-group row">
                          <div class="col-sm-4 ">
                            <b-button
                              type="button"
                              to="/admin"
                              variant="light"
                              class="w-100"
                              >Kembali</b-button
                            >
                          </div>

                          <div class="col-sm-3 ">
                            <b-button
                              type="submit"
                              variant="primary"
                              class="w-100"
                              >Simpan</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lanng="scss" scoped>
.form-control {
  border: 1.5px solid #ced4da !important;
}
</style>
